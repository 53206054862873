<template src="./FirstAdmissionA.html">
</template>

<script>
import ArrobaIdIcon from '@/components/Icons/ArrobaIdIcon';

export default {
  name: 'first-admission-a',
  components: { ArrobaIdIcon },
  methods: {
    redirectTo(route) {
      this.$router.push({path: `${route}`})
    }
  }
}
</script>

<style lang="scss" scoped src="./FirstAdmissionA.scss">
</style>
